import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import BlogCard from '../components/BlogCard';
import CtaNewsletter from '../components/CtaNewsletter';

import '../css/blog/blogpage.scss';

export default function BlogPage({ data, location }) {
  const allBlogs = data.allSanityBlogs.nodes;
  const { title, heroText, heroImg } = data.sanityBlogpage.heroSection;
  const { seo } = data.sanityBlogpage;

  return (
    <>
      <SEO
        title={seo.title || title}
        description={seo.description && seo.description}
        image={seo.ogImage && seo.ogImage.asset.url}
        location={location}
      />

      <Layout>
        <Hero
          tag="Blog"
          title={title}
          para={heroText}
          heroImg={heroImg.asset.url}
        />

        <section id="blog-listing">
          <div className="wrapper">
            <div className="all-blog-cards">
              {allBlogs.map((blog, i) => (
                <BlogCard
                  key={i}
                  href={blog.slug.current}
                  title={blog.title}
                  author={blog.author}
                  date={blog.date}
                  image={blog.thumbnail.asset.gatsbyImageData}
                  alt={blog.thumbnail.alt}
                />
              ))}
            </div>
          </div>
        </section>

        <CtaNewsletter />
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityBlogpage {
      heroSection {
        title
        heroText
        heroImg {
          asset {
            gatsbyImageData
            url
          }
        }
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
    allSanityBlogs(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug {
          current
        }
        thumbnail {
          alt
          asset {
            gatsbyImageData
          }
        }
        author
        date(formatString: "MMM D, YYYY")
      }
    }
  }
`;
